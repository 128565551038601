var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"messageList"},[(_vm.types == 1 || _vm.types == 2)?_c('div',{staticClass:"tables"},[_c('el-table',{key:_vm.tableKey,attrs:{"data":_vm.dataList.data,"header-cell-style":_vm.headerStyle,"row-style":_vm.rowStyle}},[_c('el-table-column',{attrs:{"width":"340px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"oneTbody"},[_c('div',[_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v("招标")])],1),_c('div',[_c('div',{staticClass:"elissipis-text",staticStyle:{"width":"266px"},attrs:{"title":row.notice_init && row.notice_init.bidding_name}},[_vm._v(" "+_vm._s(row.notice_init && row.notice_init.bidding_name)+" ")]),_c('div',{staticClass:"elissipis-text",staticStyle:{"width":"266px"}},[_vm._v(_vm._s(row.notice_init && row.notice_init.serial_number)+" ")])])])]}}],null,false,3755829484)},[_c('template',{slot:"header"},[_c('div',{staticClass:"change-bidding-header"},[_vm._v("招标名称")]),_c('div',{staticClass:"change-bidding-header"},[_vm._v("招标编号")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"elissipis-text",attrs:{"title":row.username}},[_vm._v(_vm._s(row.notice_init && row.notice_init.username))]),_c('div',[_c('i',{staticClass:"el-icon-location-outline"}),_vm._v(" "+_vm._s(row.notice_init && row.notice_init.project_address_string)+" ")])]}}],null,false,3826519571)},[_c('template',{slot:"header"},[_c('div',[_vm._v("招标人")]),_c('div',[_vm._v("项目地址")])])],2),_c('el-table-column',{attrs:{"label":"发布状态","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(new Date(row.sign_up_end_time).getTime() > new Date().getTime())?_c('el-tag',{attrs:{"size":"small"}},[_vm._v("发布中")]):_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v("已结束")])]}}],null,false,698512626)}),_c('el-table-column',{attrs:{"width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.tender_type_name))]),_c('div',[_vm._v(" "+_vm._s(row.bidding_way == 1 ? "平台公开" : "入围公开"))])]}}],null,false,379560252)},[_c('template',{slot:"header"},[_c('div',[_vm._v("招标类型")]),_c('div',[_vm._v("招标方式")])])],2),_c('el-table-column',{attrs:{"width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.release_start_time))]),_c('div',[_vm._v(" "+_vm._s(row.sign_up_end_time))])]}}],null,false,3067315325)},[_c('template',{slot:"header"},[_c('div',[_vm._v("发布开始时间")]),_c('div',[_vm._v("报名截止时间")])])],2),_c('el-table-column',{attrs:{"label":"操作","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tbody",on:{"click":function($event){return _vm.gotoOther(scope.row)}}},[_vm._v("查看详情")])]}}],null,false,2077987998)})],1)],1):_vm._e(),(_vm.types == 5)?_c('div',[_c('el-table',{key:_vm.tableKey,attrs:{"data":_vm.dataList.data,"header-cell-style":_vm.headerStyle,"row-style":_vm.rowStyle}},[_c('el-table-column',{attrs:{"width":"340px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"oneTbody",staticStyle:{"width":"300px"}},[_c('div',[_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v("变更")])],1),_c('div',[_c('div',{staticClass:"elissipis-text",staticStyle:{"width":"266px"},attrs:{"title":row.notice && row.notice.notice_init && row.notice.notice_init.bidding_name}},[_vm._v(" "+_vm._s(row.notice && row.notice.notice_init && row.notice.notice_init.bidding_name)+" ")]),_c('div',{staticClass:"elissipis-text",staticStyle:{"width":"266px"}},[_vm._v(_vm._s(row.notice && row.notice.notice_init && row.notice.notice_init.serial_number))])])])]}}],null,false,1663460433)},[_c('template',{slot:"header"},[_c('div',{staticClass:"change-bidding-header"},[_vm._v("招标名称")]),_c('div',{staticClass:"change-bidding-header"},[_vm._v("招标编号")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"elissipis-text",attrs:{"title":row.change_name}},[_vm._v(_vm._s(row.change_name))]),_c('div',[_vm._v(_vm._s(row.change_serial_number))])]}}],null,false,2328720300)},[_c('template',{slot:"header"},[_c('div',[_vm._v("变更名称")]),_c('div',[_vm._v("变更编号")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"elissipis-text",attrs:{"title":row.notice && row.notice.notice_init && row.notice.notice_init.username}},[_vm._v(" "+_vm._s(row.notice && row.notice.notice_init && row.notice.notice_init.username))]),_c('div',[_vm._v(_vm._s(row.notice && row.notice.notice_init && row.notice.notice_init.project_address_string))])]}}],null,false,402866752)},[_c('template',{slot:"header"},[_c('div',[_vm._v("招标人")]),_c('div',[_vm._v("项目地址")])])],2),_c('el-table-column',{attrs:{"prop":"change_release_at","label":"发布时间"}}),_c('el-table-column',{attrs:{"label":"操作","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tbody",on:{"click":function($event){return _vm.gotoOther(scope.row)}}},[_vm._v("查看详情")])]}}],null,false,2077987998)})],1)],1):_vm._e(),(_vm.types == 6)?_c('div',[_c('el-table',{key:_vm.tableKey,attrs:{"data":_vm.dataList.data,"header-cell-style":_vm.headerStyle,"row-style":_vm.rowStyle}},[_c('el-table-column',{attrs:{"width":"340px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"oneTbody"},[_c('div',[_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v("中标")])],1),_c('div',[_c('div',{staticClass:"elissipis-text",staticStyle:{"width":"266px"},attrs:{"title":row.notice && row.notice.bidding_name}},[_vm._v(" "+_vm._s(row.notice && row.notice.bidding_name)+" ")]),_c('div',[_vm._v(_vm._s(row.notice && row.notice.serial_number))])])])]}}],null,false,2113397245)},[_c('template',{slot:"header"},[_c('p',{staticClass:"change-bidding-header"},[_vm._v("招标名称")]),_c('p',{staticClass:"change-bidding-header"},[_vm._v("招标编号")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"elissipis-text"},[_vm._v(_vm._s(row.notice && row.notice.username))]),_c('div',{staticClass:"elissipis-text"},[_vm._v(_vm._s(row.notice && row.notice.project_address_string))])]}}],null,false,308159602)},[_c('template',{slot:"header"},[_c('p',[_vm._v("招标人")]),_c('p',[_vm._v("项目地址")])])],2),_c('el-table-column',{attrs:{"label":"发布状态","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.award_end_status)?_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v("已结束")]):_c('el-tag',{attrs:{"size":"small"}},[_vm._v("发布中")])]}}],null,false,2907403429)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.notice && row.notice.notice_block_set)?_c('div',[_c('p',_vm._l((row.notice.notice_block_set),function(item,index){return _c('span',{key:item.id},[_vm._v(" "+_vm._s(item.tender_type_name)+" ")])}),0)]):_vm._e(),(row.notice && row.notice.bidding_way_plus_invite)?_c('p',[(row.notice.bidding_way_plus_invite == 1)?[_vm._v(" 平台公开 ")]:(row.notice.bidding_way_plus_invite == 1)?[_vm._v(" 入围公开 ")]:(row.notice.bidding_way_plus_invite == 3)?[_vm._v(" 邀请 ")]:(row.notice.bidding_way_plus_invite == 4)?[_vm._v(" 平台公开+邀请 ")]:(row.notice.bidding_way_plus_invite == 4)?[_vm._v(" 入围公开+邀请 ")]:[_vm._v(" 平台公开 ")]],2):_vm._e()]}}],null,false,67846405)},[_c('template',{slot:"header"},[_c('p',[_vm._v("招标类型")]),_c('p',[_vm._v("招标方式")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.publicity_start_at))]),_c('p',[_vm._v(_vm._s(row.publicity_end_at))])]}}],null,false,334436221)},[_c('template',{slot:"header"},[_c('p',[_vm._v("公示开始时间")]),_c('p',[_vm._v("公示开始时间")])])],2),_c('el-table-column',{attrs:{"label":"操作","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tbody",on:{"click":function($event){return _vm.gotoOther(scope.row)}}},[_vm._v("查看详情")])]}}],null,false,2077987998)})],1)],1):_vm._e(),(_vm.types == 7)?_c('div',[_c('el-table',{attrs:{"size":"small","data":_vm.dataList.data,"header-cell-style":_vm.headerStyle,"row-style":_vm.rowStyle}},[_c('el-table-column',{attrs:{"label":" ","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.issued == 2)?_c('el-tag',{attrs:{"size":"small","type":"primary"}},[_vm._v("快询")]):_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v("询价")])]}}],null,false,45721370)}),_c('el-table-column',{attrs:{"min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"text_address",attrs:{"title":row.title}},[_vm._v(" "+_vm._s(row.title)+" ")]),_c('div',{staticClass:"text_address",attrs:{"title":row.serial_number}},[_vm._v(" "+_vm._s(row.serial_number)+" ")])])]}}],null,false,237094772)},[_c('template',{slot:"header"},[_c('div',[_vm._v("询价标题")]),_c('div',[_vm._v("询价单号")])])],2),_c('el-table-column',{attrs:{"min-width":"270px"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',[_vm._v("询价人")]),_c('div',[_vm._v("询价方式")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.department_name)?_c('div',[_c('div',{staticClass:"text_address",attrs:{"title":row.department_name}},[_vm._v(" "+_vm._s(row.department_name)+" ")])]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(row.inquiry_way_text)+" ")])]}}],null,false,3979586963)}),_c('el-table-column',{attrs:{"min-width":"160px"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',[_vm._v("发布状态")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.inquiry_status == 1)?_c('el-tag',{attrs:{"size":"mini","type":"info"}},[_vm._v("已结束")]):_c('el-tag',{attrs:{"size":"mini"}},[_vm._v("发布中")])],1)]}}],null,false,1508640241)}),_c('el-table-column',{attrs:{"min-width":"140px"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',[_vm._v("发布开始时间")]),_c('div',[_vm._v("发布结束时间")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.inquiry_issued_start_time)+" ")]),_c('div',[_vm._v(" "+_vm._s(row.inquiry_issued_end_time)+" ")])]}}],null,false,1937605939)}),_c('el-table-column',{attrs:{"label":"操作","min-width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tbody",on:{"click":function($event){return _vm.gotoOther(scope.row)}}},[_vm._v("查看详请")])]}}],null,false,3001422444)})],1)],1):_vm._e(),_c('div',{staticClass:"footer"},[_c('el-pagination',{attrs:{"current-page":_vm.dataList.current_page,"page-sizes":[10, 20, 30, 40],"page-size":10,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.dataList.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }