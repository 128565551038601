<template>
  <div class="allCode">
    <navList @thisType="thisType" :types="type" />
    <div>
      <iframe
        v-if="showIf"
        id="ifraHome"
        width="100%"
        :height="heigths + 'px'"
        :src="url"
        frameborder="0"
      ></iframe>
    </div>
    <div class="mains" style="margin-top:20px;">
      <positionList
        :types="type"
        ref="positionList"
        @sumits="sumits"
        :dataList="dataList"
      />
    </div>
    <div class="mains">
      <messageList
        @changeSize="changeSize"
        :dataList="dataList"
        @search="search"
        :types="type"
      />
    </div>
    <footers class="footer-margin" :key="footer_key" />
  </div>
</template>

<script>
import footers from "@/components/footer/footer_tpl.vue";
import { get } from "@/server/ajax.js";
import navList from "./components/navList.vue";
import positionList from "./components/positionList.vue";
import messageList from "./components/messageList.vue";
export default {
  components: {
    positionList,
    messageList,
    navList,
    footers,
  },
  data() {
    return {
      showIf: false,
      heigths: 0,
      url: "",
      type: 1,
      dataList: {},
      footer_key: 0,
      msgListKey: 0,
      formData: {
        title: "",
        page: 1,
        page_size: 10,
        preliminary_data_type: "0", //招标公告是否资格预审;是否开启资格预审;0-关闭;1-开启
        notice_status: "", // release 发布中;expired 已过期
        tag_release_time_init: "", // 发布时间 标签  1-今天,2-近7天,3-近一个月,4-近半年
        search_start_release_start_time: "", // 开始时间
        search_end_release_start_time: "", // 结束时间
        bidding_way: "", // 招标方式;1-平台公开,2-入围公开
        inquiry_way: "", //方式 询价方式，platform=平台公开,shortlisted=入围公开，只允许取这两个值
        inquiry_status: "", // 发布中/已过期
        inquiry_time: "", // 发布时间
        inquiry_time_diy: {
          start_time: "",
          end_time: "",
        },
      },
    };
  },
  created() {
    let type = this.$route.query.type;
    this.type = type || 7;
    if (Number(this.$route.query.company_id)) {
      this.formData.company_id = this.$route.query.company_id;
      this.$axios
        .get(
          "/jc/public/get_homepage_apaas?model_name=inquiry_company&company_id=" +
            this.formData.company_id
        )
        .then((res) => {
          this.url = `${location.origin}/_apass/lookcustomV3?id=${res.data.page_id}`;
          this.showIf = true;
        });
    } else {
      this.$axios
        .get("/jc/public/get_homepage_apaas?model_name=inquiry_platform")
        .then((res) => {
          this.url = `${location.origin}/_apass/lookcustomV3?id=${res.data.page_id}`;
          this.showIf = true;
        });
    }
    this.query();
  },
  mounted() {
    window.addEventListener("message", this.setHeight);
  },
  methods: {
    setHeight(event) {
      if (event?.data.height) {
        this.heigths = event.data.height || 300;
      }
      if (event?.data.url) {
      }
    },
    changeSize(val) {
      if (val.type == "size") {
        this.formData.page_size = val.num;
      } else if (val.type == "page") {
        this.formData.page = val.num;
      }
      this.query();
    },
    search(data) {
      this.formData.title = data;
      this.query();
    },
    filterEmptyData() {
      let obj = {};
      for (let key in this.formData) {
        let value = this.formData[key];
        if (key == "inquiry_time_diy") {
          if (value.start_time) {
            obj.inquiry_time_diy = value;
          }
        } else if (/\d/.test(value)) {
          obj[key] = value;
        } else {
          if (value) {
            obj[key] = value;
          }
        }
      }
      return obj;
    },
    sumits(val) {
      this.formData.page = 1;
      for (let keys in val) {
        if (val[keys] === "0") {
          val[keys] = "";
        }
      }
      this.formData = {
        ...this.formData,
        ...val,
      };
      this.query();
    },
    thisType(val) {
      if (val != this.type) {
        this.type = val;
        this.$refs.positionList.reset();
        for (let keys in this.formData) {
          this.formData[keys] = "";
        }
        if (this.$route.query.company_id) {
          this.formData.company_id = this.$route.query.company_id;
        }
        this.formData.page = 1;
        this.formData.page_size = 10;
        this.dataList.data = [];
        this.query();
      }
    },
    query() {
      let obj = this.filterEmptyData();
      if (this.type == 7) {
        if (obj.preliminary_data_type) {
          delete obj.preliminary_data_type;
        }
        get("/jc/application/getMiningLists", obj).then((res) => {
          console.log("getMiningLists", res.data);
          this.dataList = res.data;
        });
      } else {
        let url = "";
        if (this.type == 1) {
          this.formData.preliminary_data_type = 0;
          url = "/bidding/front_desk/tender/tender/notice_lists";
        } else if (this.type == 2) {
          this.formData.preliminary_data_type = 1;
          url = "/bidding/front_desk/tender/tender/notice_lists";
        }
        let datas = this.filterEmptyData();
        if (this.type == 5) {
          delete datas.preliminary_data_type;
          delete datas.notice_status;
          let query = this.$route.query;
          if (query.notice) {
            datas.notice_id = query.notice;
          }
          url = "/bidding/front_desk/tender/tender/notice_change";
        }
        if (this.type == 6) {
          delete datas.preliminary_data_type;
          url = "/bidding/front_desk/tender/tender/notice_in";
        }
        if (url) {
          get(url, datas).then((res) => {
            // console.log('query',url,datas);
            if (res.data && res.data.lists && res.data.lists.data) {
              res.data.lists.data.map((item) => {
                let arr = [];
                item.notice_block_set &&
                  item.notice_block_set.map((subItem) => {
                    arr.push(subItem.tender_type_name);
                  });
                item.tender_type_name = arr.join(" ");
                if (this.type == 6) {
                  item.award_end_status =
                    new Date().getTime() > new Date(item.publicity_end_at);
                }
              });
            }
            this.dataList = res.data.lists;
            console.log("this.dataList: ", this.dataList);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mains {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}
.footer-margin {
  margin-top: 60px;
}
#ifraHome{
  margin-top: 18px;
}
</style>
