<template>
  <div class="navList">
    <header class="el_headers">
      <div class="business-header">
        <img
          src="@/assets/img/login/7899.png"
          class="buss-info"
          alt=""
          @click="openS"
        />
        <span :class="[choseOne == 7 ? 'curr' : '']" @click="choseOther(7)"
          >询价公告</span
        >
        <span class="spnsd">|</span>
        <span :class="[choseOne == 1 ? 'curr' : '']" @click="choseOther(1)"
          >招标公告
        </span>
        <span class="spnsd">|</span>
        <span :class="[choseOne == 2 ? 'curr' : '']" @click="choseOther(2)"
          >资格预审公告</span
        >
        <span class="spnsd">|</span>
        <span :class="[choseOne == 6 ? 'curr' : '']" @click="choseOther(6)"
          >中标公告</span
        >
        <span class="spnsd">|</span>
        <span :class="[choseOne == 5 ? 'curr' : '']" @click="choseOther(5)"
          >变更公告</span
        >
      </div>
      <div v-if="showZuhu">
        <span :title="showZuhu.name" style="margin-right:10px">{{ showZuhu.name }}</span>
        <img width="30px" height="30px" :src="showZuhu.logo" alt="" />
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "navList",
  props: ["types"],
  data() {
    return {
      choseOne: "",
      showZuhu: null,
    };
  },
  created() {
    this.choseOne = this.$route.query.type || 7;

    if (
      Number(this.$route.query.company_id) &&
      Number(this.$route.query.company_id) > 0
    ) {
      this.$axios
        .get("/base/company/detail", {
          company_id: Number(this.$route.query.company_id),
        })
        .then((res) => {
          this.showZuhu = {
            name: res.data.name,
            logo: res.data.company_logo,
          };
        });
    }
  },
  methods: {
    openS() {
      window.open("/messageList?type=" + this.types + "&company_id=0");
    },
    choseOther(val) {
      if (this.types == val) return;
      this.$router.push({
        path: "/messageList",
        query: {
          type: val,
          company_id: this.$route.query.company_id || 0,
        },
      });
      this.$emit("thisType", val);
      this.choseOne = val;
    },
    gotoOther() {
      this.$router.push("/BusinessHome");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/bussinessHeader.scss";

.navList {
  background-color: #fff;
  border-top: 2px solid #f5f5f5;
  width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}

.el_headers {
  display: flex;
  & > div:nth-child(1) {
    flex: 1;
    overflow: hidden;
  }
  & > div:nth-child(2) {
    width: 180px;
    display: flex;
    align-items: center;
    & > img {
      width: 30px;
      border-radius: 15px;
    }
    & > span{
      text-align: right;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
	    white-space: nowrap;
    }
  }
}
</style>
