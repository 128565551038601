<template>
  <div class="positionList">
    <!-- 变更公告没有 方式选择 -->
    <div  v-if="types == 7">
      <div class="titlesd">询价人</div>
      <div>
        <el-input
          style="width: 400px"
          size="small"
          @blur="submits"
          v-model="formData.inquiry_by"
          placeholder="请输入询价人"
        >
        </el-input>
      </div>
    </div>
    <div>
      <div class="titlesd">项目名称</div>
      <div>
        <el-input
          @blur="submits"
          style="width: 400px"
          size="small"
          v-model="formData.title"
          placeholder="请输入项目名称"
        >
        </el-input>
      </div>
    </div>
    <div v-if="types != 5">
      <div class="titlesd">{{types == 7?'询价':''}}方式</div>
      <div>
        <span
          @click="choseOtherMode(item)"
          :class="['btns', item.chose ? 'isChose' : '']"
          v-for="item in mode"
          :key="item.id"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
    <div v-if="types != 7">
      <div class="titlesd">地区</div>
      <div class="position-box">
        <span
          @click="choseOtherPosition(item)"
          :class="['position', item.chose ? 'positionChose' : '']"
          v-for="item in position"
          :key="item.id"
        >
          {{ item.name }}</span
        >
      </div>
    </div>
    <!-- 变更公告没有状态选择 -->
    <div v-if="types != 5">
      <div class="titlesd">{{types == 7?'发布':''}}状态</div>
      <div>
        <span
          @click="choseOtherStatus(item)"
          :class="['btns', item.chose ? 'isChose' : '']"
          v-for="item in status"
          :key="item.id"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
    <div>
      <div class="titlesd">{{types == 7?'结束时间':'发布时间'}}</div>
      <div>
        <span
          @click="choseOtherTimes(item)"
          :class="['btns', item.chose ? 'isChose' : '']"
          v-for="item in times"
          :key="item.id"
        >
          {{ item.name }}
        </span>
        <el-date-picker
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="changeTimes"
          style="width: 260px"
          size="small"
          v-model="timesAll"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <!-- <el-button
          @click="submits"
          style="margin-left: 20px"
          size="small"
          type="danger"
          >确认</el-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dataList", "types"],
  name: "positionList",
  data() {
    return {
      timesAll: [],
      type: [
        {
          name: "全部",
          id: "0",
          chose: true,
        },
      ],
      // ，platform=平台公开,shortlisted=入围公开，只允许取这两个值
      mode: [
        {
          name: "全部",
          id: 0,
          chose: true,
        },
        {
          name: "平台公开",
          id: 1,
          str: "platform",
          chose: false,
        },
        {
          name: "入围公开",
          id: 2,
          str: "shortlisted",
          chose: false,
        },
      ],
      //   发布状态，0=结束，1发布中
      status: [
        {
          name: "全部",
          id: "0",
          num: "",
          chose: true,
        },
        {
          name: "发布中",
          id: "release",
          num: 1,
          chose: false,
        },
        {
          name: "已结束",
          id: "expired",
          num: 0,
          chose: false,
        },
      ],
      // ，today=今天，weeks近一周，month近一个月，half_a_year近半年
      times: [
        {
          name: "全部",
          id: "0",
          chose: true,
        },
        {
          name: "今日",
          id: "1",
          str: "today",
          chose: false,
        },
        {
          name: "近7天",
          id: "2",
          str: "weeks",
          chose: false,
        },
        {
          name: "近一个月",
          id: "3",
          str: "month",
          chose: false,
        },
        {
          name: "近半年",
          id: "4",
          str: "half_a_year",
          chose: false,
        },
      ],
      position: [
        {
          name: "全国",
          id: 0,
          chose: true,
        },
      ],

      formData: {
        title: "",
        inquiry_by:'', // 询价人
        tender_type_id: "", // 招标类型
        inquiry_way: "", //招标方式
        inquiry_status: "", // 发布中/已过期
        inquiry_time: "", // 发布时间
        bidding_way: "", //招标方式
        notice_status: "", // 发布中/已过期
        tag_release_time_init: "", // 发布时间
        search_start_release_start_time: "",
        search_end_release_start_time: "",

        inquiry_time_diy: {
          start_time: "",
          end_time: "",
        },
      },
    };
  },
  created() {
    this.getProvinceCode();
  },
  methods: {
    // 重置
    reset() {
      this.type.forEach((item, index) => {
        item.chose = false;
        if (index == 0) {
          item.chose = true;
        }
      });
      this.mode.forEach((item, index) => {
        item.chose = false;
        if (index == 0) {
          item.chose = true;
        }
      });
      this.status.forEach((item, index) => {
        item.chose = false;
        if (index == 0) {
          item.chose = true;
        }
      });
      this.times.forEach((item, index) => {
        item.chose = false;
        if (index == 0) {
          item.chose = true;
        }
      });
      this.position.forEach((item, index) => {
        item.chose = false;
        if (index == 0) {
          item.chose = true;
        }
      });
			this.timesAll = [];
      for (let keys in this.formData) {
        this.formData[keys] = "";
      }
    },
    submits() {
      console.log(this.formData);
      this.$emit("sumits", this.formData);
    },
    choseOther(val) {
      this.type.forEach((item) => {
        item.chose = false;
      });
      val.chose = true;
    },
    getProvinceCode() {
      this.$axios
        .get("/city/list", { parent_id: "100000", hideLoad: !0 })
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            data.map((item) => {
              item.chose = false;
            });
            this.position = this.position.concat(data);
          }
        })
        .catch((err) => {
          console.error("地址配置失败", err);
        });
    },
    choseOtherMode(val) {
      this.mode.forEach((item) => {
        item.chose = false;
      });
      val.chose = true;
      if ([1, 2, 6].includes(Number(this.types))) {
        //招标公告，资格预审公告，中标公告
        if (val.id) {
          this.formData.bidding_way = val.id;
        } else {
          this.formData.bidding_way = "";
        }
      } else if (this.types == 7) {
        //询价公告
        if (val.id) {
          this.formData.inquiry_way = val.str;
        } else {
          this.formData.inquiry_way = "";
        }
      }
      this.submits();
    },
    choseOtherPosition(val) {
      this.position.forEach((item) => {
        item.chose = false;
      });
      val.chose = true;
      if (val.id) {
        this.formData.project_address_province = val.id;
      } else {
        this.formData.project_address_province = "";
      }
      this.submits();
    },
    choseOtherStatus(val) {
      this.status.forEach((item) => {
        item.chose = false;
      });
      val.chose = true;
      if (this.types == 7) {
        //询价公告
        this.formData.inquiry_status = val.num;
      } else {
        //招投标公告 中标公告
        this.formData.notice_status = val.num ==1? "release" : (val.num ===0?"expired":'');
      }
      this.submits();
    },
    calcTime(num) {
      let time = new Date();
      function time_str(time) {
        let times = new Date(time);
        let year = times.getFullYear(),
          month = times.getMonth() + 1,
          day = times.getDate();
        function ten(num) {
          return num > 9 ? num : "0" + num;
        }
        return `${year}-${ten(month)}-${ten(day)}`;
      }
      return {
        start_time: time_str(new Date()),
        end_time: time_str(new Date().getTime() - num * 8.64e7),
      };
    },
    choseOtherTimes(val) {
      this.times.forEach((item) => {
        item.chose = false;
      });
      val.chose = true;
      this.formData.inquiry_time = val.str;

      this.timesAll = [];
      this.formData.search_start_release_start_time = "";
      this.formData.search_end_release_start_time = "";
      if (val.str == "today") {
        this.formData.tag_release_time_init = 1;
      } else if (val.str == "weeks") {
        this.formData.tag_release_time_init = 2;
      } else if (val.str == "month") {
        this.formData.tag_release_time_init = 3;
      } else if (val.str == "half_a_year") {
        this.formData.tag_release_time_init = 4;
      } else {
        this.formData.tag_release_time_init = "";
      }
      this.submits();
    },
    changeTimes(val) {
      this.times.forEach((item) => {
        item.chose = false;
      });
			if(val){
				this.formData.tag_release_time_init = "";
				this.formData.inquiry_time = "";
				this.formData.search_start_release_start_time = val[0];
				this.formData.search_end_release_start_time = val[1];
			}else{
				this.formData.search_start_release_start_time = '';
				this.formData.search_end_release_start_time = '';
			}
      this.submits();
    },
  },
};
</script>

<style lang="scss" scoped>
.positionList {
  color: #666;
  background-color: #fff;
  padding: 20px 30px 20px 40px;
  & > div {
    margin-bottom: 20px;
    display: flex;
    & > div:nth-child(1) {
      line-height: 32px;
      width: 60px;
      font-weight: 800;
      margin-right: 20px;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
  }
}
.more-btn {
  .more-text {
    color: #fe2838;
  }
}
.btns {
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  width: 90px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #ccc;
  margin-right: 32px;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: red;
    border-color: red;
  }
}
.isChose {
  color: red;
  border-color: red;
}
.position-box {
  display: flex;
  flex-wrap: wrap;
  .position {
    line-height: 32px;
    user-select: none;
    margin-right: 32px;
    cursor: pointer;
    &:hover {
      color: red;
    }
    &.positionChose {
      color: red;
    }
  }
}
</style>
